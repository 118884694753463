<template>
	<div class="cassie-vertical-md">
		<DataSubjectBackButton @click="$emit('exit')" />
		<v-card>
			<div class="d-flex">
				<v-card-title>
					{{ cardTitleText }}
				</v-card-title>
				<v-spacer />
				<v-switch
					v-model="sortAsc"
					label="Sort Newest to Oldest"
					class="mr-4"
				/>
			</div>
			<v-card-text class="pt-0">
				<v-timeline
					align-top
					dense
				>
					<v-timeline-item
						v-for="(item, i) in timelineItems"
						:key="i"
						:color="item.color"
						:icon="item.icon"
						fill-dot
						right
					>
						<DataSubjectAuditHistoryTimelineCard
							v-bind="item"
							:can-see-preferences="canSeePreferences"
							:submission="item"
							:syrenis-id="syrenisId"
							:submissions="submissions"
							:can-see-contact-details="canSeeContactDetails"
							:submission-index="i"
						/>
					</v-timeline-item>
				</v-timeline>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import {
	timelineColors,
	timelineIcons,
	timelineLabels
} from './data-subject-constants.js'
import DataSubjectBackButton from './data-subject-back-button.vue'
import DataSubjectAuditHistoryTimelineCard from './data-subject-audit-history-timeline-card.vue'
import { auditHistorySortNewestToOldest, brandFieldLabel } from '../../../../../../shared/state/configuration.js'
import {
	getDataSubjectSubmissions
} from '../../../../../../shared/utils/api/data-subject.js'
export default {
	components: { DataSubjectAuditHistoryTimelineCard, DataSubjectBackButton },
	props: {
		getCurrentSelection: Function,
		canSeePreferences: Boolean,
		canSeeContactDetails: Boolean,
		syrenisId: Number
	},
	setup () {
		return {
			auditHistorySortNewestToOldest,
			brandFieldLabel
		}
	},
	data () {
		return {
			sortAsc: false,
			tabs: [{ title: 'View by Submission', slot: '0' }, { title: 'View by Preference', slot: '1' }],
			tab: '0',
			submissions: []
		}
	},
	computed: {
		preferenceTableHeaders () {
			return [
				{
					value: 'brandName',
					text: brandFieldLabel.value
				},
				{
					value: 'channelName',
					text: 'Channel'
				},
				{
					value: 'parentChannelName',
					text: 'Parent Channel'
				},
				{
					value: 'dateSubmitted',
					text: 'Consent Date'
				},
				{
					value: 'preferenceValue',
					text: 'Preference'
				},
				{
					value: 'isCurrent',
					text: 'Is Current'
				},
				{
					text: '',
					value: 'data-table-expand'
				}
			]
		},
		cardTitleText () {
			if (this.canSeePreferences && this.canSeeContactDetails) {
				return 'Preferences and Contact Details Audit'
			}
			return !this.canSeePreferences ? 'Contact Details Audit' : 'Preferences Audit'
		},
		timelineItems () {
			const timelineData = [...this.submissions].sort((a, b) =>
				this.sortAsc ? new Date(b.dateSubmitted) - new Date(a.dateSubmitted) : new Date(a.dateSubmitted) - new Date(b.dateSubmitted)
			)
			return timelineData.map(item => ({
				...item,
				title: timelineLabels[item.submissionLogType],
				color: timelineColors[item.submissionLogType],
				icon: timelineIcons[item.submissionLogType]
			}))
		}
	},
	created () {
		this.getTimelineData(this.syrenisId)
		if (auditHistorySortNewestToOldest.value) {
			this.sortAsc = true
		}
	},
	methods: {
		async getTimelineData (syrenisId) {
			await getDataSubjectSubmissions(syrenisId).then(data => {
				data.submissions.forEach(ds => {
					ds.flatMap(sub => this.submissions.push(sub))
				})
			})
		}
	}
}
</script>
