<template>
	<div class="d-flex">
		<TextField
			:value="`${privacyPolicyName} ${privacyPolicyVersion ? ' - ' + privacyPolicyVersion : ''}`"
			label="Privacy Policy"
			view-only
			style="flex: 1;"
		/>
		<PrimaryActionButton
			:disabled="!privacyPolicyUrl"
			class="ml-2 py-4"
			@click="viewPolicy"
		>
			View
		</PrimaryActionButton>
	</div>
</template>

<script>
import TextField from '../../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
export default {
	name: 'data-subject-privacy-policy',
	components: { PrimaryActionButton, TextField },
	props: {
		privacyPolicyName: String,
		privacyPolicyVersion: String,
		privacyPolicyUrl: String
	},
	methods: {
		viewPolicy () {
			window.open(this.privacyPolicyUrl)
		}
	}
}
</script>
