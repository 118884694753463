<template>
	<div class="cassie-vertical-sm">
		<v-row
			v-if="showValidFromAndToFieldsForPrefSubmissions"
			dense
		>
			<v-col :cols="6">
				<TextField
					:value="validFrom"
					label="Valid From"
					view-only
				/>
			</v-col>
			<v-col :cols="6">
				<TextField
					:value="validTo"
					label="Valid To"
					view-only
				/>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col :cols="6">
				<TextField
					:value="sourceCode"
					:label="sourceCodeFieldLabel"
					view-only
				/>
			</v-col>
			<v-col :cols="6">
				<TextField
					:value="responseChannel"
					:label="responseChannelFieldLabel"
					view-only
				/>
			</v-col>
		</v-row>
		<v-textarea
			:value="statementText"
			label="Statement Text"
			outlined
			hide-details
			light
			height="90px"
			readonly
			filled
			persistent-placeholder
			no-resize
			class="mb-4"
		/>
		<DataSubjectPrivacyPolicy
			:privacy-policy-name="privacyPolicyName"
			:privacy-policy-version="privacyPolicyVersion"
			:privacy-policy-url="privacyPolicyUrl"
			style="flex: 1;"
		/>
		<template v-if="hasExtendedPreferenceOptions">
			<v-sheet>
				<div class="text-subtitle-1 font-weight-medium">
					Extended Preferences
				</div>
				<v-simple-table
					dense
					class="mb-4"
				>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									scope="col"
									style="width: 50%;"
								>
									Key
								</th>
								<th
									class="text-left"
									scope="col"
									style="width: 50%;"
								>
									Value
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="extendedPref in extendedPrefs"
								:key="extendedPref.value"
							>
								<td class="text-left">
									{{ extendedPref.keyLabel }}
								</td>
								<td class="text-left">
									{{ extendedPref.value }}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-sheet>
		</template>
		<template v-if="hasOtherProfiles && showProfileTable">
			<v-sheet>
				<div class="text-subtitle-1 font-weight-medium">
					Preference on Other Profiles
				</div>
				<v-simple-table
					dense
					class="mb-4"
				>
					<template #default>
						<thead>
							<tr>
								<th
									v-for="field in profileTableHeaders"
									:key="field.value"
									scope="col"
									class="text-left"
								>
									{{ field.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="profile in profileTableRows"
								:key="profile.syrenisId"
							>
								<td
									v-for="field in Object.keys(profile)"
									:key="field"
									class="text-left"
								>
									{{ profile[field] }}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-sheet>
		</template>
		<template v-if="!hasOtherProfiles && !showProfileTable && !hasExtendedPreferenceOptions">
			<v-sheet>
				<div class="text-subtitle-1 font-weight-medium">
					Preference on Other Profiles
				</div>
				<div class="text-body-2">
					No other profiles found
				</div>
			</v-sheet>
		</template>
		<div v-else />
	</div>
</template>

<script>
import TextField from '../../../../../../../shared/components/text-field.vue'
import DataSubjectPrivacyPolicy from '../data-subject-privacy-policy.vue'
import { brandFieldLabel, responseChannelFieldLabel, sourceCodeFieldLabel, showValidFromAndToFieldsForPrefSubmissions, showProfileTable, profileTableHeaders } from '../../../../../../../shared/state/configuration.js'
export default {
	components: { DataSubjectPrivacyPolicy, TextField },
	props: {
		dateSubmitted: {
			type: String,
			default: ''
		},
		statementText: {
			type: String,
			default: ''
		},
		responseChannel: {
			type: String,
			default: ''
		},
		sourceCode: {
			type: String,
			default: ''
		},
		privacyPolicyName: {
			type: String,
			default: ''
		},
		privacyPolicyUrl: {
			type: String,
			default: null
		},
		privacyPolicyVersion: {
			type: String,
			default: ''
		},
		otherProfiles: {
			type: Array,
			default: () => []
		},
		extendedPrefs: {
			type: Array,
			default: () => []
		},
		validFrom: {
			type: String,
			default: ''
		},
		validTo: {
			type: String,
			default: ''
		}
	},
	setup () {
		return {
			brandFieldLabel,
			responseChannelFieldLabel,
			sourceCodeFieldLabel,
			showValidFromAndToFieldsForPrefSubmissions,
			showProfileTable,
			profileTableHeaders
		}
	},
	computed: {
		hasOtherProfiles () {
			return this.otherProfiles.length
		},
		hasExtendedPreferenceOptions () {
			return this.extendedPrefs.length
		},
		profileTableRows () {
			return this.otherProfiles.map(profile => {
				return this.profileTableHeaders.map(header => {
					return profile[header.text]
				})
			})
		}
	}
}
</script>
