<template>
	<div>
		<div class="d-flex justify-space-between mb-4">
			<DataSubjectBackButton @click="$emit('exit')"	/>
			<SecondaryActionButton
				v-if="masterCanEditPreferences"
				:disabled="!canEditPreferences"
				@click="$emit('edit')"
			>
				<v-icon
					left
					dark
				>
					mdi-pencil
				</v-icon>
				Edit preferences
			</SecondaryActionButton>
		</div>
		<SectionCard>
			<template #title>
				Current Preferences
			</template>
			<template #body>
				<DataSubjectPreferenceExpansionPanels
					:preference-groups="preferenceGroups"
					:view-mode="true"
					:brand-id="brandId"
				>
					<template #header>
						<th class="text-left">
							Consent / Preference
						</th>
						<th class="text-left">
							{{ brandFieldLabel }}
						</th>
						<th class="text-left">
							Source
						</th>
						<th class="text-left">
							Last Updated Date
						</th>
						<th class="text-left">
							Profiles
						</th>
						<th class="text-left">
							Current Selection
						</th>
						<th class="text-center">
							Action
						</th>
					</template>
					<template #table="{ channels, parentGroupName }">
						<DataTable
							:items="tableData(channels)"
							:headers="filteredTableHeaders"
							:items-per-page="100"
							hide-default-footer
							single-expand
							show-expand
						>
							<template #expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<DataSubjectPreference
										v-bind="preferenceDetails(parentGroupName, item)"
										class="mt-4"
									/>
								</td>
							</template>
						</DataTable>
					</template>
				</DataSubjectPreferenceExpansionPanels>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import DataSubjectPreferenceExpansionPanels from '../../../../../../../shared/components/data-subjects/data-subject-preference-expansion-panels.vue'
import DataSubjectBackButton from '../data-subject-back-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import DataSubjectPreference from './data-subject-preference.vue'
import { brandFieldLabel, masterCanEditPreferences } from '../../../../../../../shared/state/configuration.js'
import SectionCard from '../../../../../../../shared/components/section-card.vue'

export default {
	components: { SectionCard, DataSubjectPreference, DataTable, SecondaryActionButton, DataSubjectBackButton, DataSubjectPreferenceExpansionPanels },
	props: {
		canEditPreferences: Boolean,
		preferenceGroups: {
			type: Array,
			default: () => []
		},
		profilesByChannelId: {
			type: Function,
			required: true
		},
		syrenisId: {
			type: Number,
			required: true
		},
		getCurrentSelection: {
			type: Function,
			default: () => () => ''
		},
		isPartOfGroup: {
			type: Boolean,
			required: true
		},
		brandId: Number
	},
	setup () {
		return {
			brandFieldLabel,
			masterCanEditPreferences
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'Consent / Preference', value: 'channelName' },
				{ text: this.brandFieldLabel, value: 'brandName', width: '20%' },
				{ text: 'Source', value: 'source', width: '15%' },
				{ text: 'Last Updated Date', value: 'dateSubmitted', width: '15%' },
				{ text: 'Profiles', value: 'profilesCount', width: '10%' },
				{ text: 'Current Selection', value: 'currentSelection', width: '15%' },
				{ text: '', value: 'data-table-expand' }
			]
		},
		filteredTableHeaders () {
			if (!this.isPartOfGroup) {
				return this.tableHeaders.filter(({ value }) => value !== 'profilesCount')
			} else {
				return this.tableHeaders
			}
		}
	},
	methods: {
		tableData (channels) {
			return channels.map((channel, index) => ({
				...channel,
				source: channel.hasPreference ? channel.divId : '',
				dateSubmitted: channel.hasPreference ? channel.dateSubmitted : '',
				profilesCount: this.getProfilesCount(channel),
				isChannelOptedIn: channel.isChannelOptedIn,
				id: index
			}))
		},
		preferenceDetails (parentGroupName, channel) {
			return {
				parentGroupName,
				...channel,
				validFrom: channel.validFrom ? channel.validFrom : channel.dateSubmitted,
				isChannelOptedIn: channel.isChannelOptedIn,
				extendedPrefs: channel.mergedExtendedPrefs,
				otherProfiles: this.profilesByChannelId(channel.channelId)
					.filter(({ syrenisId }) => syrenisId !== this.syrenisId)
					.map(({ profile, syrenisId, preference }) => ({
						...profile,
						syrenisId,
						'Current Selection': this.getCurrentSelection(preference)
					}))
			}
		},
		getProfilesCount ({ channelId, hasPreference }) {
			if (!hasPreference) return
			return this.profilesByChannelId(channelId).length
		}
	}
}

</script>
