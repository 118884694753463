<!-- eslint-disable max-lines -->
<template>
	<ValidationForm
		#default="{ handleSubmit, reset, invalid }"
		tag="div"
		style="max-width: calc(100% - 260px); width: 100%;"
	>
		<ViewLayout class="dataSubject">
			<template #header-title>
				{{ title }}
			</template>
			<template #header-caption>
				{{ caption }}
			</template>
			<template #header-after>
				<Tabs
					:tab.sync="tab"
					:tabs="tabs"
					:disabled="editMode"
				/>
			</template>
			<template #content>
				<SectionCard v-if="!editMode && isPartOfGroup">
					<template #title>
						Profiles
					</template>
					<template #body>
						<DataTable
							:headers="profilesDataHeaders"
							:items="profilesData"
							:selected="(row) => row.syrenisId === syrenisId"
							hide-default-footer
							@click:row="selectProfile"
						/>
					</template>
				</SectionCard>
				<TabItems
					:tab="tab"
					:tabs="tabs"
				>
					<template #preferences>
						<DataSubjectPreferencesEdit
							v-if="editPreferences"
							ref="dataSubjectPreferencesEdit"
							:data-subject="currentStandDak"
							:preference-groups="preferenceGroupsMappedToEdit"
							:has-email="!!currentStandDak.email1"
							:brand-id="brandId"
							@saved="loadDataSubjects"
							@close="editPreferences = false"
							@invalid-selection="editPrefsIsInvalidSelection"
						/>
						<DataSubjectPreferences
							v-else
							:preference-groups="preferenceGroupsMappedToView"
							:get-current-selection="getCurrentSelection"
							:profiles-by-channel-id="profilesByChannelId"
							:syrenis-id="syrenisId"
							:can-edit-preferences="canEditPreferences"
							:is-part-of-group="isPartOfGroup"
							:brand-id="brandId"
							@edit="editPreferences = true"
							@exit="goToDataSubjectSearch"
						/>
					</template>
					<template #contact-details>
						<div>
							<div class="d-flex justify-space-between mb-4">
								<DataSubjectBackButton @click="goToDataSubjectSearch" />
								<SecondaryActionButton
									v-if="!editContactDetails && masterCanEditContactDetails"
									:disabled="!canEditContactDetails"
									@click="onEditContactDetails(reset)"
								>
									<v-icon
										left
										dark
									>
										mdi-pencil
									</v-icon>
									Edit Contact Details
								</SecondaryActionButton>
							</div>
							<DataSubjectContactDetails
								v-if="selectedDataSubject"
								:data-subject="editDataSubject || currentStandDak"
								:edit-contact-details="editContactDetails"
								:view-data-subject="!editContactDetails"
								@update:dataSubject="editDataSubject = $event"
							/>
						</div>
					</template>
					<template
						v-if="canSeePreferences"
						#preference-history
					>
						<DataSubjectPreferenceHistory
							:syrenis-id="syrenisId"
							:search-brand-id="brandId"
							@exit="goToDataSubjectSearch"
						/>
					</template>
					<template #submission-history>
						<DataSubjectAuditHistory
							v-bind="selectedDataSubject"
							:get-current-selection="getCurrentSelection"
							:can-see-preferences="canSeePreferences"
							:can-see-contact-details="canSeeContactDetails"
							:syrenis-id="syrenisId"
							@exit="goToDataSubjectSearch"
						/>
					</template>
					<template	#associated-devices>
						<DataSubjectAssociatedDevices
							:devices="selectedDataSubject.deviceAssociation"
							:search-brand-id="brandId"
							@exit="goToDataSubjectSearch"
						/>
					</template>
				</TabItems>
			</template>
			<template
				v-if="showManageFooter"
				#footer
			>
				<div
					v-if="editContactDetails && masterCanEditContactDetails"
					class="d-flex mt-4"
				>
					<v-spacer />
					<SecondaryActionButton
						@click="onCancelEditContactDetails(reset)"
					>
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						class="ml-4"
						@click="handleSubmit(onSaveContactDetails)"
					>
						Save
					</PrimaryActionButton>
				</div>
				<div
					v-else
					class="d-flex mt-4"
				>
					<v-spacer />
					<SecondaryActionButton @click="$refs.dataSubjectPreferencesEdit.close(reset)">
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						class="ml-4"
						:disabled="isInvalidSelection"
						@click="handleSubmit(submitPreferences, invalid)"
					>
						Save
					</PrimaryActionButton>
				</div>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import {
	getDataSubject,
	getDataSubjectAssociations,
	postDataSubject
} from '../../../../../../shared/utils/api/data-subject.js'
import { DATE_TIME_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import {
	contactDetailsFields,
	dataSubjectSearchFields,
	dataSubjectSearchResults,
	masterCanEditContactDetails,
	profileTableFields
	, preferenceGroups
} from '../../../../../../shared/state/configuration.js'
import { DATA_SUBJECT_SEARCH } from '../../../../router/route-names.js'
import DataSubjectPreferences from './data-subject-preferences/data-subject-preferences.vue'
import DataSubjectContactDetails from '../data-subject-contact-details.vue'
import DataSubjectAuditHistory from './data-subject-audit-history.vue'
import DataSubjectPreferenceHistory from './data-subject-preference-history.vue'
import DataSubjectPreferencesEdit from './data-subject-preferences/data-subject-preferences-edit.vue'
import { NO_PREFERENCE, OPTED_IN, OPTED_OUT } from './data-subject-constants.js'
import DataSubjectBackButton from './data-subject-back-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import {
	DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS,
	EDIT_DATA_SUBJECT_CONTACT_DETAILS,
	EDIT_DATA_SUBJECT_CURRENT_PREFERENCES,
	SEE_DATA_SUBJECT_CONTACT_DETAILS,
	SEE_DATA_SUBJECT_CURRENT_PREFERENCES,
	SEE_DATA_SUBJECT_PREFERENCE_HISTORY,
	SEE_DATA_SUBJECT_SUBMISSION_HISTORY
} from '../../../../../../shared/permissions/cs-portal-permissions.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import { NO_BRAND_SELECTED, UNIVERSAL_BRAND, csPortalBrandSelector } from '../../../../../../shared/state/brands.js'
import DataSubjectAssociatedDevices from './data-subject-associated-devices.vue'
export default {
	components: {
		SectionCard,
		TabItems,
		PrimaryActionButton,
		SecondaryActionButton,
		DataSubjectBackButton,
		DataSubjectPreferencesEdit,
		DataTable,
		Tabs,
		DataSubjectAuditHistory,
		DataSubjectPreferenceHistory,
		DataSubjectContactDetails,
		DataSubjectPreferences,
		ViewLayout,
		ValidationForm,
		DataSubjectAssociatedDevices
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		brandId: {
			type: Number,
			default: NO_BRAND_SELECTED
		},
		allIds: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			contactDetailsFields,
			dataSubjectSearchFields,
			dataSubjectSearchResults,
			masterCanEditContactDetails,
			showSnackbar,
			profileTableFields,
			preferenceGroups,
			UNIVERSAL_BRAND,
			csPortalBrandSelector
		}
	},
	data () {
		return {
			tab: 'preferences',
			dataSubjects: [],
			syrenisId: this.id,
			editPreferences: false,
			editContactDetails: false,
			NO_PREFERENCE,
			editDataSubject: null,
			isInvalidSelection: false
		}
	},
	computed: {
		...mapGetters('auth', ['hasPermission']),
		tabs () {
			const tabsArray = []
			if (this.canSeePreferences) {
				tabsArray.push({ title: 'Preferences', slot: 'preferences' })
			}
			if (this.canSeeContactDetails) {
				tabsArray.push({ title: 'Contact Details', slot: 'contact-details' })
			}
			if (this.canSeePreferenceHistory && this.canSeePreferences) {
				tabsArray.push({
					title: 'Preference History',
					slot: 'preference-history'
				})
			}
			if (this.canSeeSubmissionHistory) {
				tabsArray.push({
					title: 'Submission History',
					slot: 'submission-history'
				})
			}
			if (this.hasAssociatedDevices) {
				tabsArray.push({
					title: 'Associated Devices',
					slot: 'associated-devices'
				})
			}
			return tabsArray
		},
		editMode () {
			return this.editPreferences || this.editContactDetails
		},
		title () {
			return `${this.currentStandDak?.forename || ''} ${
				this.currentStandDak?.surname || ''
			}`
		},
		caption () {
			return this.contactDetailsFields
				.filter(field => field.showInOverview)
				.map(({ fieldName }) => this.currentStandDak[fieldName])
				.join(', ')
		},
		currentStandDak () {
			return this.selectedDataSubject?.currentStandDak || {}
		},
		isPartOfGroup () {
			return this.dataSubjects.length > 1
		},
		selectedDataSubject () {
			return this.dataSubjects.find(
				({ currentStandDak: { syrenisId } }) => syrenisId === this.syrenisId
			)
		},
		profilesData () {
			return this.dataSubjects.map(({ currentStandDak }) => currentStandDak)
		},
		profilesDataHeaders () {
			return this.dataSubjectSearchResults
				.filter(field => field.includeInSearchResults)
				.map(({ fieldName, fieldLabel }) => ({
					text: fieldLabel || fieldName,
					value: fieldName
				}))
		},
		dataSubjectPreferences () {
			return this.getDataSubjectPreferences(this.selectedDataSubject)
		},
		preferenceGroupsMapped () {
			const result = this.preferenceGroups.map(group => ({
				...group,
				subGroups: group.subGroups.sort((a, b) => a.channelID > b.channelID)
					.map(subGroup => ({
						...subGroup,
						channels: subGroup.channels
							.sort((a, b) => a.displayOrderInSubGroup > b.displayOrderInSubGroup)
							.filter(channel => channel.display)
							.map(channel => {
								const preferenceChannel = this.dataSubjectPreferences?.find(x => x.channelId === channel.channelId)
								return {
									...channel,
									...preferenceChannel,
									amendDateFrom: false,
									hasPreference: true,
									canBeAdded: channel.canBeAdded,
									brandName: preferenceChannel != null ? preferenceChannel.brandName : channel.brandName,
									brandID: preferenceChannel != null ? preferenceChannel.brandID : channel.brandId,
									channelId: preferenceChannel != null ? preferenceChannel.channelId : channel.channelId,
									channelName: preferenceChannel != null ? preferenceChannel.channelName : channel.channelName,
									privacyPolicyUrl: preferenceChannel != null ? preferenceChannel.privacyPolicyURL : channel.privacyPolicyURL,
									privacyPolicyName: preferenceChannel != null ? preferenceChannel.privacyPolicyName : channel.privacyPolicyName,
									privacyPolicyVersion: preferenceChannel != null ? preferenceChannel.privacyPolicyVersion : channel.privacyPolicyVersion,
									privacyId: preferenceChannel != null ? preferenceChannel.privacyID : channel.privacyID,
									responseChannel: preferenceChannel != null ? preferenceChannel.responseChannel : channel.responseChannel,
									statementText: preferenceChannel != null ? preferenceChannel.statementText : channel.statementText,
									divId: preferenceChannel != null ? preferenceChannel.divID : channel.divID,
									dateSubmitted: preferenceChannel != null
										? format(
											new Date(preferenceChannel.dateSubmitted),
											DATE_TIME_FORMAT
										)
										: null,
									sourceCode: preferenceChannel != null ? preferenceChannel.sourceCode : channel.sourceCode,
									isOptIn: preferenceChannel != null ? preferenceChannel.isOptIn : channel.isOptIn,
									isChannelOptedIn: preferenceChannel != null ? preferenceChannel.isChannelOptedIn : channel.isChannelOptedIn,
									currentSelection: preferenceChannel != null ? preferenceChannel?.isChannelOptedIn ? OPTED_IN : OPTED_OUT : NO_PREFERENCE,
									validTo: preferenceChannel?.preferenceValidTo
										? format(
											new Date(preferenceChannel.preferenceValidTo),
											DATE_TIME_FORMAT)
										: null,
									validFrom: preferenceChannel?.preferenceValidFrom
										? format(
											new Date(preferenceChannel.preferenceValidFrom),
											DATE_TIME_FORMAT
										)
										: preferenceChannel?.consentDate
											? format(
												new Date(preferenceChannel.consentDate),
												DATE_TIME_FORMAT
											)
											: null,
									channelExtendedPrefs: channel?.extendedPrefs.filter(({ hideFromUi }) => hideFromUi === false) ?? [],
									extendedPrefs: preferenceChannel?.extendedPrefs.filter(({ hideFromUi }) => hideFromUi === false) ?? [],
									mergedExtendedPrefs: channel.extendedPrefs
										.map(channelExtPrefs => ({
											...channelExtPrefs,
											...preferenceChannel?.extendedPrefs.find(
												extPrefs => extPrefs.key === channelExtPrefs.keyName
											)
										}))
										.filter(({ hideFromUi }) => hideFromUi === false)
								}
							})
					})).filter(subGroup => subGroup.channels.length > 0)
			}))
			return result.sort((a, b) => a.displayOrder - b.displayOrder).filter(group => group.subGroups.flat().length > 0)
		},
		preferenceGroupsMappedToView () {
			const userBrandValues = csPortalBrandSelector.value.map(brand => {
				return brand.value
			})
			const result = this.preferenceGroupsMapped.map(group => ({
				...group,
				subGroups: group.subGroups.map(subGroup => ({
					...subGroup,
					channels: this.brandId !== UNIVERSAL_BRAND
						? subGroup.channels.filter(channel => channel.currentSelection !== NO_PREFERENCE || !channel.hideNoPref)
							.filter(pref => this.dataSubjectPreferences?.find(x => x.channelId === pref.channelId) || !pref.hideNoPref)
						: subGroup.channels.filter(channel => channel.currentSelection !== NO_PREFERENCE || !channel.hideNoPref && userBrandValues.includes(channel.brandId))
							.filter(pref => this.dataSubjectPreferences?.find(x => x.channelId === pref.channelId) || !pref.hideNoPref)
				})).filter(subGroup => subGroup.channels?.length > 0)
			})
			).filter(group => group.subGroups?.length > 0)
			return result
		},
		preferenceGroupsMappedToEdit () {
			const userBrandValues = csPortalBrandSelector.value.map(brand => {
				return brand.value
			})
			const result = this.preferenceGroupsMapped.map(group => ({
				...group,
				subGroups: group.subGroups.map(subGroup => ({
					...subGroup,
					channels: this.brandId !== UNIVERSAL_BRAND
						? subGroup.channels
						: subGroup.channels.filter(channel => userBrandValues.includes(channel.brandId))
				})).filter(subGroup => subGroup.channels?.length > 0)
			})
			).filter(group => group.subGroups?.length > 0)
			return result
		},
		hasFullPermissions () {
			return this.hasPermission(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)
		},
		canSeePreferences () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_CURRENT_PREFERENCES)
		},
		canSeeContactDetails () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_CONTACT_DETAILS)
		},
		canSeePreferenceHistory () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_PREFERENCE_HISTORY)
		},
		canSeeSubmissionHistory () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_SUBMISSION_HISTORY)
		},
		canEditPreferences () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(EDIT_DATA_SUBJECT_CURRENT_PREFERENCES)
		},
		canEditContactDetails () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(EDIT_DATA_SUBJECT_CONTACT_DETAILS)
		},
		showManageFooter () {
			return this.editContactDetails && this.masterCanEditContactDetails || this.editPreferences
		},
		hasAssociatedDevices () {
			return this.selectedDataSubject?.deviceAssociation?.length
		}
	},
	created () {
		this.loadDataSubjects()
	},
	methods: {
		async loadDataSubjects () {
			const responses = await Promise.all(
				this.allIds.map(id => getDataSubject(id, this.brandId))
			)
			const datasubjects = responses.map(({ data }) => data)
			for (const dataSubject of datasubjects) {
				const devices = await this.getAssociations(dataSubject.currentStandDak.syrenisId, this.brandId)
				dataSubject.deviceAssociation = devices.deviceAssociation
			}
			this.dataSubjects = datasubjects
		},
		async getAssociations (syrenisId, brandId) {
			return (await getDataSubjectAssociations(syrenisId, brandId)).data
		},
		goToDataSubjectSearch () {
			this.$router.push({ name: DATA_SUBJECT_SEARCH })
		},
		getDataSubjectPreferences (dataSubject) {
			return [...(dataSubject?.currentPreferences || [])]
		},
		selectProfile ({ syrenisId }) {
			this.syrenisId = syrenisId
		},
		profilesByChannelId (channelId) {
			return this.dataSubjects.reduce((acc, dataSubject) => {
				const preferences = this.getDataSubjectPreferences(dataSubject)
				const preference = preferences.find(
					preference => preference.channelId === channelId
				)
				const profile = {}
				this.profileTableFields.forEach(({ fieldName, fieldLabel }) => {
					if (dataSubject.currentStandDak) {
						profile[fieldLabel] = dataSubject.currentStandDak[fieldName]
					}
				})
				if (preference) {
					acc.push({
						syrenisId: dataSubject.currentStandDak.syrenisId,
						profile,
						preference
					})
				}
				return acc
			}, [])
		},
		getCurrentSelection ({ isChannelOptedIn }) {
			return isChannelOptedIn ? OPTED_IN : OPTED_OUT
		},
		async onSaveContactDetails () {
			this.editDataSubject.submissionSource = 'Update Contact Details'
			await postDataSubject({ dataSubject: this.editDataSubject })
			await this.loadDataSubjects()
			this.showSnackbar('Your changes have been saved')
			this.editContactDetails = false
			this.editDataSubject = null
		},
		onEditContactDetails (resetValidation) {
			this.editDataSubject = JSON.parse(JSON.stringify(this.currentStandDak))
			resetValidation()
			this.editContactDetails = true
		},
		onCancelEditContactDetails (resetValidation) {
			resetValidation()
			this.editContactDetails = false
			this.editDataSubject = null
		},
		filterChannels (channel) {
			if (!this.editMode) {
				return channel.hasPreference === true || channel.hideNoPref === false
			}
			return channel
		},
		editPrefsIsInvalidSelection (value) {
			this.isInvalidSelection = value
		},
		submitPreferences (invalid) {
			if (invalid) return
			return this.$refs.dataSubjectPreferencesEdit.save()
		}
	}
}
</script>
