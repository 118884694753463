<template>
	<div
		class="cassie-vertical-md pa-4"
		style="width: 100%"
	>
		<div v-if="preference.mergedStandDak">
			Merged from ID: {{ preference.mergedStandDak.syrenisId }} {{ caption }}
		</div>
		<v-simple-table
			dense
			light
			width="100%"
		>
			<template #default>
				<thead>
					<tr>
						<th
							class="text-left"
							scope="col"
						>
							Div ID
						</th>
						<th
							class="text-left"
							scope="col"
						>
							{{ sourceCodeFieldLabel }}
						</th>
						<th
							class="text-left"
							scope="col"
						>
							{{ responseChannelFieldLabel }}
						</th>
						<th
							class="text-left"
							scope="col"
						>
							Privacy Policy
						</th>
						<th
							v-if="showValidFromAndToFieldsForPrefSubmissions"
							class="text-left"
							scope="col"
						>
							Valid From
						</th>
						<th
							v-if="showValidFromAndToFieldsForPrefSubmissions"
							class="text-left"
							scope="col"
						>
							Valid To
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{ preference.divId }}
						</td>
						<td>
							{{ preference.sourceCode }}
						</td>
						<td>
							{{ preference.responseChannel }}
						</td>
						<td>
							{{ preference.privacyPolicyName }}
							<span v-if="preference.privacyPolicyUrl">
								-
								<a
									:href="preference.privacyPolicyUrl"
									target="blank"
								>
									View
								</a>
							</span>
						</td>
						<td
							v-if="showValidFromAndToFieldsForPrefSubmissions"
						>
							{{ preference.validFrom }}
						</td>
						<td
							v-if="showValidFromAndToFieldsForPrefSubmissions"
						>
							{{ preference.validTo }}
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		<div
			v-if="preference.statementText"
			class="cassie-vertical-md"
		>
			<div
				class="text-subtitle-2"
			>
				Statement
			</div>
			<v-card
				v-dompurify-html="preference.statementText"
				flat
				style="border: 1px solid currentColor !important;"
				class="background pa-4 d-flex"
			/>
		</div>
		<div
			v-if="preference.headerText"
			class="cassie-vertical-md"
		>
			<div
				class="text-subtitle-2"
			>
				Header
			</div>
			<v-card
				v-dompurify-html="preference.headerText"
				flat
				style="border: 1px solid currentColor !important; height: 100px;"
				class="background pa-4 d-flex"
			/>
		</div>
		<div
			v-if="preference.footerText"
			class="cassie-vertical-md"
		>
			<div
				class="text-subtitle-2"
			>
				Footer
			</div>
			<v-card
				v-dompurify-html="preference.footerText"
				flat
				style="border: 1px solid currentColor !important; height: 100px;"
				class="background pa-4 d-flex"
			/>
		</div>
		<div
			v-if="!!preference.extendedPreferences.length"
			class="cassie-vertical-md"
		>
			<div
				class="text-subtitle-2"
			>
				Extended Preference Data
			</div>
			<v-simple-table
				dense
				light
				width="100%"
			>
				<template #default>
					<tbody>
						<tr
							v-for="extendedPref in preference.extendedPreferences"
							:key="extendedPref.key"
						>
							<td>
								{{ extendedPref.key }}
							</td>
							<td>
								{{ extendedPref.value }}
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<SecondaryActionButton
			v-if="canSeeSubmissionString && preference.submissionId !== null"
			@click="openSubmissionModal()"
		>
			Show Raw Submission
		</SecondaryActionButton>
		<Modal
			v-if="canSeeSubmissionString && preference.submissionId !== null && showSubmissionStringModal"
			width="600px"
		>
			<template #modal-title>
				Show Raw Submission
				<v-spacer />
				<IconButton @click="closeSubmissionModal()">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<vue-json-pretty
						v-if="validJson && rawSubmissionString"
						:data="rawSubmissionString"
					/>
					<div v-else>
						{{ rawSubmissionString }}
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="closeSubmissionModal">
					Close
				</SecondaryActionButton>
			</template>
		</Modal>
	</div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { mapGetters } from 'vuex'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { responseChannelFieldLabel, sourceCodeFieldLabel, showValidFromAndToFieldsForPrefSubmissions, contactDetailsFields } from '../../../../../../shared/state/configuration.js'
import { getDataSubjectSubmissionString } from '../../../../../../shared/utils/api/data-subject.js'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import {
	DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS,
	SEE_DATA_SUBJECT_SUBMISSION_STRING
} from '../../../../../../shared/permissions/cs-portal-permissions.js'

export default {
	components: { VueJsonPretty, Modal, SecondaryActionButton, IconButton },
	props: {
		preference: {
			required: true,
			type: Object
		},
		syrenisId: Number,
		currentExpandedItem: Object
	},
	setup () {
		return {
			responseChannelFieldLabel,
			sourceCodeFieldLabel,
			showValidFromAndToFieldsForPrefSubmissions,
			contactDetailsFields
		}
	},
	data () {
		return {
			rawSubmissionString: null,
			loading: false,
			validJson: false,
			expanded: false,
			showSubmissionStringModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['hasPermission']),
		hasFullPermissions () {
			return this.hasPermission(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)
		},
		canSeeSubmissionString () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_SUBMISSION_STRING)
		},
		caption () {
			return this.contactDetailsFields
				.filter(field => field.showInOverview)
				.map(({ fieldName }) => this.preference.mergedStandDak[fieldName])
				.join(', ')
		}
	},
	watch: {
		currentExpandedItem () {
			this.currentItemChanged()
		}
	},
	methods: {
		currentItemChanged () {
			this.expanded = !this.expanded
			this.validJson = false
			this.rawSubmissionString = null
		},
		getSubmissionString () {
			this.expanded = !this.expanded
			if (this.canSeeSubmissionString && this.preference.submissionId !== null && !this.rawSubmissionString) {
				this.loading = true
				getDataSubjectSubmissionString(this.preference.submissionId)
					.then(data => {
						try {
							this.validJson = true
							this.rawSubmissionString = JSON.parse(data)
							// if submission string is null, then it is not available
							if (!this.rawSubmissionString) {
								this.validJson = false
								this.rawSubmissionString = JSON.parse(data) ?? 'Submission String is not available'
							}
						} catch (error) {
							this.validJson = false
							this.rawSubmissionString = data ?? 'Submission String is not available'
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		openSubmissionModal () {
			if (this.canSeeSubmissionString && this.preference.submissionId !== null && !this.rawSubmissionString) {
				this.loading = true
				getDataSubjectSubmissionString(this.preference.submissionId)
					.then(data => {
						try {
							this.validJson = true
							this.rawSubmissionString = JSON.parse(data)
							// if submission string is null, then it is not available
							if (!this.rawSubmissionString) {
								this.validJson = false
								this.rawSubmissionString = JSON.parse(data) ?? 'Submission String is not available'
							}
						} catch (error) {
							this.validJson = false
							this.rawSubmissionString = data ?? 'Submission String is not available'
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
			this.showSubmissionStringModal = true
		},
		closeSubmissionModal () {
			this.rawSubmissionString = null
			this.validJson = false
			this.showSubmissionStringModal = false
		}
	}
}

</script>
