<!-- eslint-disable max-lines -->
<template>
	<SectionCard>
		<template #title>
			Update Current Preferences
		</template>
		<template #body>
			<v-card
				v-if="showHeaderFooterForPrefSubmissions || showResponseChannelForPrefSubmissions || showSourceCodeForPrefSubmissions || showSendPrefConfirmationEmail"
				class="background mb-4"
				flat
			>
				<v-card-title>
					Update Details
				</v-card-title>
				<v-card-text class="d-flex">
					<template v-if="showHeaderFooterForPrefSubmissions || showResponseChannelForPrefSubmissions || showSourceCodeForPrefSubmissions">
						<div class="cassie-vertical-md">
							<Dropdown
								v-if="showHeaderFooterForPrefSubmissions"
								v-model="selectedHeaderFooterCombinationId"
								:items="headerFooterCombinationOptions"
								:label="headerFooterLabel"
								:rules="{ required: headerFooterForSubmissionsMandatory }"
								style="width: 650px;"
							/>
							<Dropdown
								v-if="showResponseChannelForPrefSubmissions"
								v-model="selectedResponseChannelId"
								:items="responseChannelOptions"
								:label="responseChannelLabel"
								:rules="{ required: responseChannelForSubmissionsMandatory }"
								style="width: 650px;"
							/>
							<Dropdown
								v-if="showSourceCodeForPrefSubmissions"
								v-model="selectedSourceCodeId"
								:items="sourceCodeOptions"
								:label="sourceCodeLabel"
								:rules="{ required: sourceCodeForSubmissionsMandatory }"
								style="width: 650px;"
							/>
						</div>
						<v-spacer />
					</template>
					<v-switch
						v-if="showSendPrefConfirmationEmail && hasEmail"
						v-model="sendConfirmationEmail"
						label="Send Confirmation Email"
					/>
				</v-card-text>
			</v-card>
			<v-card
				v-if="headerText"
				class="mb-4"
			>
				<v-card-text v-dompurify-html="headerText" />
			</v-card>
			<DataSubjectPreferenceExpansionPanels
				ref="expansionPanels"
				:preference-groups="editPreferenceGroups"
				:is-creating="false"
				@cascade-selection="cascadeSelectionToSubGroup"
			>
				<template #header>
					<th
						class="text-left"
						style="width: 17%"
					>
						Consent / Preference
					</th>
					<th
						class="text-left"
						scope="col"
						style="width: 10%"
					>
						Current Selection
					</th>
					<th
						class="text-left"
						scope="col"
					>
						New Selection
					</th>
					<th
						v-if="canEditValidFromAndToFieldsForPrefSubmissions"
						class="text-left"
						scope="col"
						style="width: 20%"
					>
						Valid From
					</th>
					<th
						v-if="canEditValidFromAndToFieldsForPrefSubmissions"
						class="text-left"
						scope="col"
						style="width: 10%"
					>
						Valid To
					</th>
					<th
						class="text-left"
						scope="col"
						style="width: 15%"
					>
						Privacy Policy
					</th>
				</template>
				<template #rows="{ channels }">
					<template v-for="channel in channels">
						<tr
							v-if="channel.canBeAdded"
							:key="`1-${channel.channelId}`"
						>
							<td style="word-break: break-word;">
								{{ channel.channelName }}
							</td>
							<td>
								{{ channel.currentSelection }}
							</td>
							<td>
								<v-radio-group
									:value="getValue(channel, 'preferenceValue')"
									row
									dense
									:error="invalidChannelSelection(channel)"
									hide-details
									class="ma-0 pa-0"
									@change="setChannelOverride(channel, 'preferenceValue', $event)"
								>
									<v-radio
										v-for="{ value, text } in preferenceOptions"
										:key="value"
										:label="text"
										:value="value"
										@change="setChannelOverride(channel, 'preferenceValue', $event)"
									/>
								</v-radio-group>
							</td>
							<td v-if="canEditValidFromAndToFieldsForPrefSubmissions">
								<v-row align="center">
									<v-col cols="8">
										<DatePicker
											:date="getValue(channel, 'validFrom')"
											:display-date="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE || getValue(channel, 'amendDateFrom') !== true ? `Valid immediately` : `${getValue(channel, 'validFrom')}`"
											:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE || getValue(channel, 'amendDateFrom') !== true"
											small
											@update:date="setChannelOverride(channel, 'validFrom', $event)"
										/>
									</v-col>
									<v-col cols="2">
										<v-checkbox
											:value="getValue(channel, 'amendDateFrom')"
											class="shrink m-0 p-0"
											label="Amend"
											:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
											@change="setChannelOverride(channel, 'amendDateFrom', $event)"
										/>
									</v-col>
								</v-row>
							</td>
							<td v-if="canEditValidFromAndToFieldsForPrefSubmissions">
								<DatePicker
									:date="getValue(channel, 'validTo')"
									:display-date="`${getValue(channel, 'validTo')}`"
									:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
									small
									@update:date="setChannelOverride(channel, 'validTo', $event)"
								/>
							</td>
							<td>
								<TextField
									v-if="getValue(channel, 'isChannelOptedIn') === NO_CHANGE_VALUE"
									:value="getValue(channel, 'privacyPolicyName')"
									label="Privacy policy"
									readonly
									dense
								/>
								<Dropdown
									v-else
									:validate-on-show="true"
									:value="getValue(channel, 'privacyId')"
									:items="csPortalPrivacyPolicies(brandId === 0 ? channel.brandId : brandId)"
									:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
									:rules="isPrivacyPolicyMandatory(channel)"
									:label="privacyPolicyMandatory ? 'Privacy Policy *' : 'Privacy Policy'"
									custom-sort
									small
									class="py-2"
									@input="setChannelOverride(channel, 'privacyId', $event)"
								/>
							</td>
						</tr>
						<tr
							v-if="channel.mergedExtendedPrefs && channel.mergedExtendedPrefs.length && channel.canBeAdded"
							:key="`2-${channel.channelId}`"
						>
							<td colspan="6">
								<v-row
									dense
									class="py-2"
								>
									<v-col
										v-for="extendedPref in channel.mergedExtendedPrefs"
										:key="extendedPref.keyId"
										cols="3"
									>
										<ExtendedPreferenceField
											v-if="!extendedPref.hideFromUi"
											:value="getValue(channel, 'mergedExtendedPrefs', extendedPref.keyId)"
											:label="extendedPref.keyLabel"
											:disabled="!extendedPref.isEditable || getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
											:field-type-id="extendedPref.fieldTypeId"
											:available-values-if-dropdown="extendedPref.availableValuesIfDropdown"
											:rules="{required:
												extendedPref.isMandatory &&
												extendedPref.isEditable &&
												getValue(channel, 'preferenceValue') !== NO_CHANGE_VALUE}"
											small
											@input="setChannelOverride(channel, 'extendedPrefs', $event, extendedPref.keyId)"
										/>
									</v-col>
								</v-row>
							</td>
						</tr>
					</template>
				</template>
			</DataSubjectPreferenceExpansionPanels>
			<v-card
				v-if="footerText"
				class="mt-4"
			>
				<v-card-text v-dompurify-html="footerText" />
			</v-card>
		</template>
	</SectionCard>
</template>

<script>
/* eslint-disable max-lines */

import { format } from 'date-fns'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import { postDataSubjectPreferenceChanges } from '../../../../../../../shared/utils/api/data-subject.js'
import { DATE_FORMAT_DATE_PICKER } from '../../../../../../../shared/utils/date-formatting.js'
import { OPTED_IN, OPTED_OUT } from '../data-subject-constants.js'
import DataSubjectPreferenceExpansionPanels from '../../../../../../../shared/components/data-subjects/data-subject-preference-expansion-panels.vue'
import {
	headerFooterCombinations,
	headerFooterForSubmissionsMandatory,
	responseChannelForSubmissionsMandatory,
	responseChannels,
	headerFooterFieldLabel,
	sendPrefConfirmationEmailDefaultState,
	showHeaderFooterForPrefSubmissions,
	showResponseChannelForPrefSubmissions,
	showSendPrefConfirmationEmail,
	responseChannelFieldLabel,
	privacyPolicyMandatory,
	showSourceCodeForPrefSubmissions,
	sourceCodes,
	sourceCodeFieldLabel,
	canEditValidFromAndToFieldsForPrefSubmissions,
	sourceCodeForSubmissionsMandatory, channelRequiredStanDakFields, contactDetailsFields
} from '../../../../../../../shared/state/configuration.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import DatePicker from '../../../../../../../shared/components/date-picker.vue'
import { csPortalPrivacyPolicies } from '../../../../../../../shared/state/privacy-policies.js'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import ExtendedPreferenceField from '../../extended-preference-field.vue'

const NO_CHANGE_VALUE = -1
const today = format(new Date(), DATE_FORMAT_DATE_PICKER)

export default {
	components: { ExtendedPreferenceField, SectionCard, DatePicker, TextField, Dropdown, DataSubjectPreferenceExpansionPanels },
	props: {
		dataSubject: {
			type: Object
		},
		preferenceGroups: {
			type: Array,
			default: () => []
		},
		hasEmail: {
			type: Boolean,
			default: false
		},
		brandId: {
			type: Number
		}
	},
	setup (props) {
		return {
			sendConfirmationEmail: sendPrefConfirmationEmailDefaultState.value && props.hasEmail,
			headerFooterCombinations,
			headerFooterFieldLabel,
			showHeaderFooterForPrefSubmissions,
			headerFooterForSubmissionsMandatory,
			showResponseChannelForPrefSubmissions,
			responseChannelForSubmissionsMandatory,
			showSendPrefConfirmationEmail,
			responseChannels,
			channelRequiredStanDakFields,
			contactDetailsFields,
			showSnackbar,
			csPortalPrivacyPolicies,
			privacyPolicyMandatory,
			showSourceCodeForPrefSubmissions,
			sourceCodes,
			sourceCodeFieldLabel,
			sourceCodeForSubmissionsMandatory,
			canEditValidFromAndToFieldsForPrefSubmissions
		}
	},
	data () {
		return {
			preferenceOverrides: {},
			selectedHeaderFooterCombinationId: null,
			selectedResponseChannelId: null,
			selectedSourceCodeId: null,
			NO_CHANGE_VALUE,
			parentGroupSelections: {}
		}
	},
	computed: {
		headerFooterLabel () {
			return `Select ${this.headerFooterFieldLabel}${this.headerFooterForSubmissionsMandatory ? ' *' : ''}`
		},
		responseChannelLabel () {
			return `${responseChannelFieldLabel.value}${this.responseChannelForSubmissionsMandatory ? ' *' : ''}`
		},
		sourceCodeLabel () {
			return `${sourceCodeFieldLabel.value}${this.sourceCodeForSubmissionsMandatory ? ' *' : ''}`
		},
		headerFooterCombinationOptions () {
			return this.headerFooterCombinations.map(({ combinationId, headerName, footerName }) => ({
				value: combinationId,
				text: `${headerName}${footerName ? ' / ' + footerName : ''}`
			}))
		},
		responseChannelOptions () {
			return this.responseChannels.map(({ responseChannelId, responseChannelName }) => ({
				value: responseChannelId,
				text: responseChannelName
			}))
		},
		sourceCodeOptions () {
			return this.sourceCodes.map(({ sourceCodeId, sourceCodeName }) => ({
				value: sourceCodeId,
				text: sourceCodeName
			}))
		},
		selectedHeaderFooterCombination () {
			return this.headerFooterCombinations.find(({ combinationId }) => combinationId === this.selectedHeaderFooterCombinationId)
		},
		footerText () {
			return this.selectedHeaderFooterCombination?.footerText
		},
		headerText () {
			return this.selectedHeaderFooterCombination?.headerText
		},
		editPreferenceGroups () {
			return JSON.parse(JSON.stringify(this.preferenceGroups))
		},
		preferenceOptions () {
			return [{ value: 1, text: OPTED_IN }, { value: 0, text: OPTED_OUT }, { value: NO_CHANGE_VALUE, text: 'No Change' }]
		}
	},
	created () {
		if (showResponseChannelForPrefSubmissions.value) {
			this.selectedResponseChannelId = responseChannels.value.find(({ isDefault }) => isDefault)?.responseChannelId
		}
		if (showSourceCodeForPrefSubmissions.value) {
			this.selectedSourceCodeId = sourceCodes.value.find(({ isDefault }) => isDefault)?.sourceCodeId
		}
	},
	methods: {
		close (resetValidation = () => {}) {
			resetValidation()
			this.$emit('close')
		},
		getRequiredFields (channels, dataSubject) {
			if (!channels || !channels.length) {
				return []
			}
			const requiredFields = []
			this.channelRequiredStanDakFields
				.filter(({ channelId }) => channels.includes(channelId)).map(x => x.requiredFields).flat().forEach(requiredField => {
					if (!requiredFields.includes(requiredField)) {
						requiredFields.push(requiredField)
					}
				})
			const missingFields = requiredFields.filter(field => dataSubject[field]?.length === 0 || dataSubject[field] === null)
			const missingFieldLabels = this.contactDetailsFields.filter(({ fieldName }) => missingFields.includes(fieldName))
				.map(({ fieldName, fieldLabel }) => {
					const label = fieldLabel.length === 0 ? fieldName : fieldLabel
					return label
				})
			return missingFieldLabels
		},
		async save () {
			const {
				dataSubject,
				sendConfirmationEmail,
				preferenceOverrides,
				selectedHeaderFooterCombinationId,
				selectedResponseChannelId
			} = this
			let sourceCode = null
			if (showSourceCodeForPrefSubmissions.value) {
				sourceCode = sourceCodes.value.find(({ sourceCodeId }) => sourceCodeId === this.selectedSourceCodeId)?.sourceCodeName
			}

			dataSubject.submissionSource = 'Update Prefs'
			const preferences = Object.values(preferenceOverrides)

			for (let index = 0; index < preferences.length; index++) {
				const preference = preferences[index]
				if (this.privacyPolicyMandatory && preference.privacyId == null) {
					this.showSnackbar({ text: 'You must select a privacy policy', color: 'red' })
					return
				}
			}

			await postDataSubjectPreferenceChanges({
				sendConfirmationEmail,
				selectedHeaderFooterCombinationId,
				selectedResponseChannelId,
				sourceCode,
				dataSubject,
				preferences: preferences
			})

			this.showSnackbar('Your changes have been saved')

			this.$emit('saved')
			this.close()
		},
		getValue (channel, property, id) {
			const preferenceOverride = this.preferenceOverrides[channel.channelId]
			const extendedPreference = channel.mergedExtendedPrefs.find(option => option.keyId === id)
			if (!preferenceOverride) {
				if (property === 'preferenceValue') return NO_CHANGE_VALUE
				if (property === 'channelId') return channel.channelId
				if (property === 'validFrom') return channel.validFrom || channel.dateSubmitted ? channel.validFrom || channel.dateSubmitted : ''
				if (property === 'validTo') return channel.validTo || ''
				if (property === 'privacyId') return channel.privacyId || null
				if (property === 'privacyPolicyName') return `${channel.privacyPolicyName} - ${channel.privacyPolicyVersion}` || ''
				if (property === 'mergedExtendedPrefs') {
					return extendedPreference?.value || ''
				}
				if (property === 'amendDateFrom') return channel.amendDateFrom
				return undefined
			} else {
				if (property === 'mergedExtendedPrefs') {
					const matchingKeyId = preferenceOverride.extendedPrefs.find(({ keyId }) => keyId === id)
					return matchingKeyId.value
				}
				return preferenceOverride[property]
			}
		},
		invalidChannelSelection (channel) {
			const preferenceOverride = this.preferenceOverrides[channel.channelId]
			if (preferenceOverride?.preferenceValue === 0 || preferenceOverride?.preferenceValue === 1) {
				const requiredFields = this.getRequiredFields([channel.channelId], this.dataSubject)
				return requiredFields.length > 0
			} else {
				return false
			}
		},
		cascadeSelectionToSubGroup (parentGroupId, subGroupId, selectedValue) {
			this.editPreferenceGroups.forEach(group => {
				if (group.parentGroupId === parentGroupId) {
					group.subGroups.forEach(subGroup => {
						if (subGroup.subGroupId === subGroupId) {
							subGroup.channels
								.filter(channel => channel.canBeAdded)
								.forEach(channel => {
									this.setChannelOverride(channel, 'preferenceValue', selectedValue !== undefined ? selectedValue : NO_CHANGE_VALUE)
								})
						}
					})
				}
			})
		},
		setChannelOverride (channel, property, value, id) {
			const isIndividualChange = value !== NO_CHANGE_VALUE && value !== 1 && value !== 0

			if (value === NO_CHANGE_VALUE) {
				this.$delete(this.preferenceOverrides, channel.channelId)
				return
			} else {
				const requiredStandakFields = this.getRequiredFields([channel.channelId], this.dataSubject)
				if (requiredStandakFields?.length > 0) {
					this.showSnackbar({
						text: `Please fill in the following fields in Contact Details: ${requiredStandakFields.join(', ')}`,
						color: 'error'
					})
				}
			}

			const override = this.preferenceOverrides[channel.channelId]
			if (!override) {
				this.$set(this.preferenceOverrides, channel.channelId, {
					channelId: channel.channelId,
					[property]: value,
					validFrom: today,
					validTo: this.calculateValidToForPicker(channel.expiryDays),
					extendedPrefs: channel.mergedExtendedPrefs
				})
			} else {
				if (property === 'extendedPrefs' || property === 'mergedExtendedPrefs') {
					const matchingKeyId = this.preferenceOverrides[channel.channelId].extendedPrefs.find(({ keyId }) => keyId === id)
					this.$set(matchingKeyId, 'value', value)
				} else {
					if (property === 'preferenceValue') {
						this.preferenceOverrides[channel.channelId].extendedPrefs.forEach(extPref => {
							if (extPref.fieldTypeId === 1) {
								this.$set(extPref, 'value', extPref.defaultFreeTextValue || '')
							} else if (extPref.fieldTypeId === 2) {
								const defaultDropdownValue = extPref.availableValuesIfDropdown.find(({ valueId }) => valueId === extPref.defaultSingleSelectValueId)
								if (defaultDropdownValue) {
									this.$set(extPref, 'value', defaultDropdownValue.value)
								} else {
									this.$set(extPref, 'value', '')
								}
							} else {
								this.$set(extPref, 'value', '')
							}
						})
						this.$set(override, property, value)
					}
					this.$set(override, property, value)
				}
			}
			if (property === 'preferenceValue') {
				this.handleGroupSelectionReset(channel, isIndividualChange)
				this.isInvalidSelection()
			}
		},
		handleGroupSelectionReset (channel, isIndividualChange) {
			let parentGroupId = null
			let subGroupId = null

			this.editPreferenceGroups.forEach(group => {
				group.subGroups.forEach(subGroup => {
					if (subGroup.channels.some(ch => ch.channelId === channel.channelId)) {
						parentGroupId = group.parentGroupId
						subGroupId = subGroup.subGroupId
					}
				})
			})

			if (isIndividualChange && parentGroupId !== null && subGroupId !== null) {
				this.$refs.expansionPanels.resetGroupSelection(parentGroupId, subGroupId)
			}
		},

		isPrivacyPolicyMandatory (channel) {
			return {
				required: Boolean(privacyPolicyMandatory.value && this.getValue(channel, 'preferenceValue') !== NO_CHANGE_VALUE)
			}
		},
		calculateValidToForPicker (days) {
			if (days == null) {
				return ''
			}
			const result = new Date()
			result.setDate(result.getDate() + days)
			return format(result, DATE_FORMAT_DATE_PICKER)
		},
		isInvalidSelection () {
			const preferences = Object.values(this.preferenceOverrides)
			const optInandOutPreferences = preferences.filter(({ preferenceValue }) => preferenceValue === 1 || preferenceValue === 0).map(x => x.channelId)
			const requiredStandakFields = this.getRequiredFields(optInandOutPreferences, this.dataSubject)
			this.$emit('invalid-selection', requiredStandakFields?.length > 0)
		}
	}
}
</script>
